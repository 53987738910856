'use es6';

import './polyFills';
import SprocketMenu from './SprocketMenu';
import PostFilterWidget from './PostFilterWidget';
document.addEventListener('DOMContentLoaded', function init() {
  var menu = new SprocketMenu(window.hsVars);
  menu.showToolsMenuIfAuthor();
  var postFilterWidget = new PostFilterWidget();
  postFilterWidget.setup();
  document.removeEventListener('DOMContentLoaded', init);
}, false);
export default SprocketMenu;